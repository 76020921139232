<template>
  <div
    class="widgetContainer bg-white widgetContainer--scrollable pullFundsAmount">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('move_fund_navTitle') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" ref="pullFundsAmount" :model="formData">
        <el-form-item
          prop="source"
          class="is-no-asterisk"
          :label="$t('pay_section_source')">
          <el-input :value="getSelectedAccount.label" />
        </el-form-item>

        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('pay_section_destination')">
          <el-select
            v-model="formData.selectedBank"
            :disabled="!destinationAccounts.length"
            :placeholder="setAccountSeletionPlaceholder"
            style="width: 100%">
            <el-option
              v-for="account in destinationAccounts"
              :key="account.id"
              :label="account.label"
              :value="account.accountNumber" />
          </el-select>
        </el-form-item>

        <el-form-item
          class="is-no-asterisk"
          prop="amount"
          :label="$t('pay_row_amount')">
          <Amount :masked="true" v-model="formData.amount" />
        </el-form-item>

        <el-form-item
          prop="description"
          class="is-no-asterisk"
          :label="$t('description')">
          <el-input v-model="formData.description" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="isFormValid"
        @click="sendTransfer"
        class="el-button__brand brand width-100">
        {{ $t('transfer') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Amount from '@/components/Amount';

export default {
  components: {
    Amount
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('account', ['getAllAccount']),
    ...mapGetters('user',['getPersonDetails']),
    isFormValid() {
      return !(
        !!this.formData.selectedBank &&
        !!this.getSelectedAccount &&
        !!this.formData.amount &&
        !!this.formData.description
      );
    },
    destinationAccounts() {
      return this.getAllAccount.filter((a) => a.id !== this.getSelectedAccount.id);
    },
    setAccountSeletionPlaceholder() {
      return this.destinationAccounts.length ? 'Select' : this.$t('no_accounts_found');
    }
  },
  data() {
    return {
      formData: {
        selectedBank: null,
        amount: '',
        description: ''
      }
    };
  },
  methods: {
    ...mapActions('contact', ['createContact']),
    ...mapActions('pay', ['intrabankPay']),
    sendTransfer() {
      const destinationAccount = this.destinationAccounts.find(a => a.accountNumber === this.formData.selectedBank);
      if(destinationAccount) {
        const loader = this.showLoader();
        this.createContact({
          accountId: this.getSelectedAccount.id,
          name: destinationAccount.label,
          intrabank: {
            accountNumber: this.formData.selectedBank
          }
        }).then((res) => {
          this.intrabankPay({
            accountId: this.getSelectedAccount.id,
            contactId: res.id,
            amount: this.formData.amount,
            description: this.formData.description
          }).then(() => {
            this.$emit('goTo', 'intraBankFundOutSuccess');
          }).finally(() => {
            loader.close();
          })
        }).catch(() => {
          loader.close();
        });
      }
    }
  }
};
</script>

<style lang="scss">
.pullFundsAmount {
  position: relative;

  .el-button__brand {
    text-transform: capitalize;
  }
}
</style>
